import type { Ref } from 'vue';
import type { Order } from '@/features/orders';
import { orderPickingCompletionPluginRegister } from '../helpers';
import type {
  OrderPickingCompletionPluginDto,
  UseOrderPickingCompletion,
} from '../types';

export function useOrderPickingCompletion(): UseOrderPickingCompletion {
  const completePicking = async (
    order: Ref<Order | undefined>,
  ): Promise<void> => {
    if (order.value === undefined) {
      return;
    }

    const orderPickingCompletionPluginDto: OrderPickingCompletionPluginDto = {
      order: order.value,
    };

    const orderPickingCompletionPluginPipeline =
      orderPickingCompletionPluginRegister();

    await orderPickingCompletionPluginPipeline.execute(
      orderPickingCompletionPluginDto,
    );
  };

  return {
    completePicking,
  };
}
